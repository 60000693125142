@font-face {
font-family: '__ppeikoFont_a5d9f8';
src: url(/_next/static/media/65ea315b0d06bee9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__ppeikoFont_Fallback_a5d9f8';src: local("Arial");ascent-override: 82.46%;descent-override: 11.24%;line-gap-override: 34.39%;size-adjust: 106.72%
}.__className_a5d9f8 {font-family: '__ppeikoFont_a5d9f8', '__ppeikoFont_Fallback_a5d9f8';font-weight: 500;font-style: normal
}

